import React from 'react'
import Portfolios from '../components/portfolios'
import PortfoliosJSON from "../content/portfolios.json"
import InnovationsJSON from "../content/innovations.json"

export default function wirelessTechnologies() {
    const content = PortfoliosJSON.portfolios[0]
    const innovation = InnovationsJSON.innovations[0]
    const subcontent = {
        title: innovation.title,
        header: InnovationsJSON.header,
        portSubheader: InnovationsJSON.subheader,
        description: innovation.description,
        logo: innovation.logo,
        shortName: content.shortName
        
    }
    return (
        <Portfolios content={content} subcontent={subcontent} />
    )
}
